import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityLayout, SliceLayoutBoundary } from '@shapeable/ui';
import { SliceLayoutColorStrip } from '../slice-layouts';
import { BANNER_KNOWLEDGE_HUB, BLUE_OVERLAY } from '../../theme';

import { EntityTitleHeader } from '@shapeable/web';

// -------- Styles -------->


const ContainerStyles = breakpoints({
  base: css`
  `,
});

const ContentLayoutStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
    margin-top: ${theme.UNIT(8)};
  `,
  tablet: css`
    margin-top: ${theme.UNIT(10)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(12)}; 
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
  ContentLayout: styled(ContentEntityLayout)`${ContentLayoutStyles}`,
};

export const PageLayoutDefault: PageLayoutComponent = (props) => {
  const { entity, slices, className } = props;

  return (
    <My.Container className={className}>
      <EntityTitleHeader overlayColor={BLUE_OVERLAY} backgroundImage={BANNER_KNOWLEDGE_HUB} entity={entity} />
      <SliceLayoutColorStrip />
      <SliceLayoutBoundary verticalPadding='none'>
        <My.ContentLayout 
          showTitles
          showMeta={false}
          showImage={false}
          entity={entity} />
      </SliceLayoutBoundary>
      {slices}
    </My.Container>
  )
};
