import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { PostsProvider as Provider, platformProviderValue } from '@shapeable/ui';

const POSTS_QUERY = graphql`
  query PlatformPostsQuery {
    platform {
      allPosts { 
        id name name_fr path slug __typename
        intro { plain text }
        intro_fr { text }
        content { text }
        content_fr { text }
        title
        title_fr
        
        openGraph { image { thumbnails { full { url } } } }
        type { id name slug __typename }
        published
        topics {
          openGraph { image { url } }
          _schema { label pluralLabel }
          __typename id name slug
        }
        challenges {
          openGraph { image { url } }
          _schema { label pluralLabel }
          __typename id name slug
        }
        authors {
          __typename
          openGraph { image { url } }
          id name slug path
          organisation { id name }
          linkedin
          photo { id url static { childImageSharp { gatsbyImageData } } }
        }
      }
    }
  }`;

export const PostsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(POSTS_QUERY), 'allPosts' ) }>{children}</Provider>;
};