import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard } from '@shapeable/web';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`
    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      font-weight: 600;
      line-height: 1.15em;
      letter-spacing: -0.06ch;
      margin-top: ${theme.UNIT(6)};
      margin-bottom: ${theme.UNIT(2)};
      font-family: ${theme.FONT('sans')};
      font-size: ${36/16}em;
    }

    .shp--page-layout-standard__subtitle {
      font-weight: 400;
      margin-bottom: ${theme.UNIT(16)};
      
      strong, b {
        font-weight: 500;
      }
    }
  `,
  tablet: css`
    .shp--page-layout-standard__title {
      font-size: ${48/15}em;
      line-height: 1.15em;
    }

    .shp--page-layout-standard__subtitle {
      font-size: 2.25em;
      margin-bottom: ${theme.UNIT(16)};
    }
  `,
  desktop: css`
    .shp--page-layout-standard__title {
      font-size: ${80/15}em;
      line-height: 1.25em;
    }

    .shp--page-layout-standard__subtitle {
      font-size: 2.5em;
      margin-bottom: ${theme.UNIT(30)};
    }
  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;
  
  return (
    <My.Layout 
      entity={entity}
      slices={slices}
      headerProps={{ showOverlayGradient: true, aspectRatio: { base: 4/3, landscape: 21 / 9, tablet: 21 / 9 }, showSlideControls: true }}
      className={className}
    />
  )
};
